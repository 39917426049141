<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="1M"
            label="변경 작업 기간"
            name="mocPeriod"
            v-model="searchParam.mocPeriod"
          />
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 발의부서 -->
          <c-dept type="search" label="발의부서" name="initiativeDeptCd" v-model="searchParam.initiativeDeptCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행단계 -->
          <c-select
            stepperGrpCd="MOC_EMERGENCY_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="mocStepCd"
            label="진행단계"
            v-model="searchParam.mocStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="비상변경-MOC 목록"
      tableId="mocemergency01"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add_circle" @btnClicked="openPopup" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>

    <!-- <q-dialog v-model="createDialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">비상변경시행 항목</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-list>
            <q-item v-for="(item, idx) in relatedWorkItems"
              :key="idx"
              tag="label" v-ripple>
              <q-item-section avatar>
                <q-checkbox v-model="relatedWorks" :val="item.code" color="orange" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{item.codeName}}</q-item-label>
                <q-item-label caption v-html="$comm.convertHtml(item.description)"></q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="전체선택" @click.prevent="allChoice" />
          <q-btn flat label="등록진행" @click.prevent="insertMOC" />
          <q-btn flat label="취소" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog> -->
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'moc-emergency',
  data() {
    return {
      searchParam: {
        plantCd: null,
        mocPeriod: [],
        initiativeDeptCd: null,
        mocStepCd: null,
        mocTypeCd: 'MT00000010',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'mocStepName',
            field: 'mocStepName',
            label: '진행단계',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'mocTitle',
            field: 'mocTitle',
            label: '제목',
            align: 'left',
            sortable: true,
            style: 'width:250px',
            type: 'link',
          },
          // {
          //   name: 'bizApprStepNm',
          //   field: 'bizApprStepNm',
          //   label: '결재진행단계',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true
          // },
          {
            name: 'mocTypeName',
            field: 'mocTypeName',
            label: '변경구분',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'changeFlag',
            field: 'changeFlag',
            label: '변경유지여부',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'Y', stepperMstNm: '유지', colorClass: 'deep-purple' },
              { stepperMstCd: 'N', stepperMstNm: '원복', colorClass: 'green' },
            ],
          },
          {
            name: 'mocNo',
            field: 'mocNo',
            label: '관리번호',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'mocDates',
            field: 'mocDates',
            label: '변경 작업 기간',
            align: 'center',
            style: 'width:150px',
            sortable: true
          },
          {
            name: 'initiativeDeptName',
            field: 'initiativeDeptName',
            label: '발의부서',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'initiativeUserName',
            field: 'initiativeUserName',
            label: '발의자',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      insertUrl: '',
      createDialog: false,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.moc.change.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      if (!row) return;
      this.openPopup(row)
    },
    openPopup(row) {
      this.popupOptions.title = '변경관리 상세'; // 변경관리 상세
      this.popupOptions.param = {
        sopMocId: row ? row.sopMocId : '',
        mocTypeCd: row ? row.mocTypeCd : 'MT00000010',
        mocStepCd: row ? row.mocStepCd : '',
      };
      this.popupOptions.target = () => import(`${'./mocDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
